 <div id="bgimg" class="bgimg">
<br>
      <div id="map-frame" class="map-frame">
        <div id="map"></div>
      </div>
    <br><br>
    <div class="bgimg2" *ngIf="!isMobileResolution">
      <br><br> <br><br><br><br><br><br><br> <br><br><br><br><br><br><br>
      <br><br> <br><br><br><br>
      <div style="padding: 5px;text-align: left;"><h2><strong>Anzeige</strong></h2></div>
      <table cellpadding="5" cellspacing="0" style="width: 100%;border-color: #9a9a9a" border="3">
        <tbody>
        <tr>
          <td><br>
            <table cellpadding="5" cellspacing="0" style="width: 100%" border="0">
              <tbody>
              <tr>
                <td style="width: 14.285%" ></td>
                <td style="width: 14.285%" > <mat-checkbox color="primary" class="example-margin" [checked]="map.hasLayer(layermarker)" (change)="changeCode(layermarker)">Markierer</mat-checkbox></td>
                <td style="width: 14.285%" ></td>
                <td style="width: 14.285%" ><mat-checkbox color="primary" class="example-margin" [checked]="map.hasLayer(layerprojekte)"  (change)="changeCode(layerprojekte)">Projekte</mat-checkbox></td>
                <td style="width: 14.285%" ></td>
                <td style="width: 14.285%" ><mat-checkbox color="primary" class="example-margin" [checked]="map.hasLayer(layerfische)"  (change)="changeCode(layerfische)">Rückmeldungen</mat-checkbox></td>
                <td style="width: 14.285%" ></td>
              </tr><br>
              <tr>
                <td style="width: 14.285%" ></td>
                <td style="width: 14.285%" ></td>
                <td style="width: 14.285%" ></td>
                <td class="bordered" style="width: 14.285%; padding: 1em" > <mat-form-field>
                  <input matInput placeholder="Wählen Sie den Code" aria-label="Code" [(ngModel)]="fisch" [formControl]='senderControl' [matAutocomplete]="auto3" >
                  <mat-autocomplete #auto3="matAutocomplete" [displayWith]="displayFn">
                    <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
                    <ng-container *ngIf="!isLoading">
                      <mat-option *ngFor="let code of filteredCode" [value]="code">
                        <span>{{ code.code }}</span>
                      </mat-option>
                    </ng-container>
                  </mat-autocomplete>
                </mat-form-field></td>
                <td style="width: 14.285%" ></td>
                <td style="width: 14.285%" ><mat-checkbox color="primary" class="example-margin" [(ngModel)]="code" (ngModelChange)="changeFisch()">Fisch anzeigen</mat-checkbox></td>
                <td style="width: 14.285%" ></td>
              </tr>
              </tbody>
            </table><br>

          </td>
        </tr>
        </tbody>
      </table>

   <br>

  <div style="padding: 5px;text-align: left;"><h2><strong>Details</strong></h2></div>
  <table cellpadding="5" cellspacing="0" style="width: 100%;border-color: #9a9a9a" border="3">
    <tbody>
    <tr>
      <td><br>
        <table cellpadding="5" cellspacing="0" style="width: 100%" border="0">
          <tbody>
          <tr>
            <td style="padding-left: 0.5em;width: 14.285%" ></td>
            <td style="padding-left: 0.5em;width: 14.285%" ><div [innerHTML]=details></div></td>
            <td style="padding-left: 0.5em;width: 14.285%" ></td>
            <td style="padding-left: 0.5em;width: 14.285%" ><div [innerHTML]=details2></div></td>
            <td style="padding-left: 0.5em;width: 14.285%" ></td>
            <td style="padding-left: 0.5em;width: 14.285%" ><div [innerHTML]=details3></div></td>
            <td style="padding-left: 0.5em;width: 14.285%" ></td>
          </tr><br>
          </tbody>
        </table><br>

      </td>
    </tr>
    </tbody>
  </table></div>

<div id="bgimg2" class="bgimg2" *ngIf="isMobileResolution">

  <div  *ngIf="screenOrientation.type===screenOrientation.ORIENTATIONS.LANDSCAPE_PRIMARY"></div>

  <div style="padding: 5px;text-align: left;"><h2><strong>Anzeige</strong></h2></div>
     <div id="table-mobile">
     <table cellpadding="5" style="width: 100%;border-color: #9a9a9a" border="3">
       <tbody>
       <tr>
         <td><br>
           <table cellpadding="5" style="width: 100%" border="0">
             <tbody>
             <tr>
               <td ><mat-checkbox color="primary" class="example-margin" [checked]="map.hasLayer(layermarker)" (change)="changeCode(layermarker)">Markierer</mat-checkbox></td>
             </tr><tr>
               <td > <mat-checkbox color="primary" class="example-margin" [checked]="map.hasLayer(layerprojekte)"  (change)="changeCode(layerprojekte)">Projekte</mat-checkbox></td>
             </tr><tr>
               <td ><mat-checkbox color="primary" class="example-margin" [checked]="map.hasLayer(layerfische)"  (change)="changeCode(layerfische)">Rückmeldungen</mat-checkbox></td>
             </tr><br>
             <table cellpadding="5" style="width: 100%" border="0">
               <tbody>
             <tr>
               <td style="width: 10%;padding-right: 0.5em"></td>
               <td class="bordered" style="width: 80%;padding-left: 0.5em; padding-top: 0.5em;margin: 0.5em"> <mat-form-field>
                 <input matInput placeholder="Wählen Sie den Code" aria-label="Code" [(ngModel)]="fisch" [formControl]='senderControl' [matAutocomplete]="auto4" >
                 <mat-autocomplete #auto4="matAutocomplete" [displayWith]="displayFn">
                   <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
                   <ng-container *ngIf="!isLoading">
                     <mat-option *ngFor="let code of filteredCode" [value]="code">
                       <span>{{ code.code }}</span>
                     </mat-option>
                   </ng-container>
                 </mat-autocomplete>
               </mat-form-field></td>
               <td style="width: 10%;padding-left: 0.5em"></td>
             </tr>
               </tbody></table><br>
             <tr>
               <td ><mat-checkbox color="primary" class="example-margin" [(ngModel)]="code" (ngModelChange)="changeFisch()">Fisch anzeigen</mat-checkbox></td>
             </tr>
             </tbody>
           </table><br>

         </td>
       </tr>
       </tbody>
     </table>

     <br>

     <div style="padding: 5px;text-align: left;"><h2><strong>Details</strong></h2></div>
     <table cellpadding="5" cellspacing="0" style="width: 100%;border-color: #9a9a9a" border="3">
       <tbody>
       <tr>
         <td><br>
           <table cellpadding="5" cellspacing="0" style="width: 100%" border="0">
             <tbody>
             <tr>
               <td style="padding: 0.5em" ><div [innerHTML]=details></div></td>

             </tr><br><tr>
               <td style="padding: 0.5em" ><div [innerHTML]=details2></div></td>

             </tr><br><tr>
               <td style="padding: 0.5em" ><div [innerHTML]=details3></div></td>

             </tr><br>
             </tbody>
           </table><br>

         </td>
       </tr>
       </tbody>
     </table></div></div>
</div><br>
